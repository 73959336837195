import React from 'react';
import { Link } from 'gatsby';

import imgL1Logo from '../../assets/image/png/l1-logo.png';
import imgL1LogoWhite from '../../assets/image/png/logo-white.png';
import { Title } from '../Core';

const Logo = ({ white, height, className = '', ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <Title color="light" variant="card" mb={0}>
        lab032
      </Title>
    </Link>
  );
};

export default Logo;
